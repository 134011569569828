import React, { createContext, useContext, useEffect, useState } from "react";

const SceneContext = createContext();

export const useScene = () => useContext(SceneContext);

const SceneProvider = ({ children }) => {
  const [sceneIndex, setSceneIndex] = useState(0);

  useEffect(() => {
    const sceneDurations = [300, 1000, 1000, 90000]; // duration per scene in ms
    const timer = setTimeout(() => {
      setSceneIndex((prev) => (prev + 1) % sceneDurations.length);
    }, sceneDurations[sceneIndex]);

    return () => clearTimeout(timer);
  }, [sceneIndex]);

  return (
    <SceneContext.Provider value={{ sceneIndex, setSceneIndex }}>
      {children}
    </SceneContext.Provider>
  );
};

export default SceneProvider;
