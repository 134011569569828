import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Users,
  Code,
  LineChart,
  FileText,
  CheckSquare,
  GitPullRequest,
  Video,
  Bot,
  Zap,
  Codepen,
} from "lucide-react";
import ProcessingCore from "./DataFlow/ProcessingCore";
import Particle from "./DataFlow/Particle";

// Departments with tasks
const departmentsWithTasks = [
  {
    name: "SE Tasks",
    icon: CheckSquare,
    color: "bg-blue-500",
    task: {
      text: "Generate custom technical integration guide",
      icon: FileText,
      color: "bg-blue-500",
    },
  },
  {
    name: "Product",
    icon: Zap,
    color: "bg-yellow-500",
    task: {
      text: "Documentation is preventing customers from integrating: +1",
      icon: LineChart,
      color: "bg-yellow-500",
    },
  },
  {
    name: "Engineering",
    icon: Code,
    color: "bg-purple-500",
    task: {
      text: "K8s AWS integration priority request",
      icon: GitPullRequest,
      color: "bg-purple-500",
    },
  },
  {
    name: "Marketing",
    icon: Users,
    color: "bg-sky-500",
    task: {
      text: "Update Battlecard to highlight our competitive advantage",
      icon: Zap,
      color: "bg-sky-500",
    },
  },
];

const OrgHeader = ({ delay }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.6,
      delay,
      type: "spring",
      stiffness: 80,
    }}
    className="mb-8"
    style={{ width: "350px" }}
  >
    <div className="flex items-center gap-3 bg-blue-500/30 rounded-lg p-3 backdrop-blur-sm">
      <div className="p-2 rounded-full bg-blue-500/50">
        <Codepen className="w-6 h-6 text-blue-200" />
      </div>
      <div className="flex flex-col">
        {/* <span className="text-blue-200 text-sm font-medium"> */}
        <span className="text-white text-sm font-medium">Completed Tasks</span>
        <span className="text-blue-300/60 text-sm">
          Automatically Routed to Departments
        </span>
      </div>
    </div>
  </motion.div>
);

const RaggityHeader = ({ delay }) => (
  <motion.div
    // initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.6,
      delay,
      type: "spring",
      stiffness: 80,
    }}
    className="mb-6"
    style={{ width: "350px" }}
  >
    <div className="flex items-center gap-3 bg-blue-500/30 rounded-lg p-3 backdrop-blur-sm">
      <div className="p-2 rounded-full bg-blue-500/50">
        <Bot className="w-6 h-6 text-blue-200" />
      </div>
      <div className="flex flex-col">
        {/* <span className="text-blue-200 text-sm font-medium"> */}
        <span className="text-white text-sm font-medium">
          raggity Automated Tasks
        </span>
        <span className="text-blue-300/60 text-sm">
          Extracted Tasks From Call
        </span>
      </div>
    </div>
  </motion.div>
);

// Set a consistent height for both DepartmentCard and TaskCard
const cardHeight = "h-14"; // Tailwind CSS class for height adjustment

// Department card component
const DepartmentCard = ({ name, icon: Icon, color }) => (
  <motion.div
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
    // className={`${color} rounded-lg px-6 py-4 flex items-center backdrop-blur-sm w-full ${cardHeight}`}
    className={`${color} rounded-lg p-4 flex items-center w-full items-center relative overflow-hidden`}
  >
    <Icon className="w-6 h-6 text-white mr-3" />
    <span className="text-white font-medium">{name}</span>
  </motion.div>
);

const TaskCard = ({ task }) => (
  <motion.div
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.6 }}
    // className={`${task.color} bg-opacity-30 rounded-lg px-6 py-4 flex items-center w-full ${cardHeight} relative overflow-hidden`}
    className={`${task.color} bg-opacity-20 rounded-lg p-4 flex w-full items-center relative overflow-hidden`}
  >
    {/* Pulsing overlay */}
    <motion.div
      className="absolute inset-0 bg-white/100"
      animate={{
        opacity: [0, 0.125, 0],
        scale: [1, 1.05, 1],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
        times: [0, 0.5, 1],
      }}
    />

    {/* Content */}
    <div
      className={`${task.color} p-2 rounded-lg bg-opacity-80 mr-3 relative z-10`}
    >
      <task.icon className="w-6 h-6 text-white" />
    </div>
    <span className="text-white text-sm font-medium relative z-10">
      {task.text}
    </span>
  </motion.div>
);

const OrgFlow = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    // Only cascade departments
    const timer = setInterval(() => {
      setActiveIndex((prev) =>
        prev < departmentsWithTasks.length - 1 ? prev + 1 : prev
      );
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-[1920px] h-[580px] bg-[#0F172A] overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full max-w-[1400px] flex justify-between items-center px-16">
          {/* Left Column - Departments with cascade */}
          <div className="w-[400px] flex flex-col">
            <div className="flex justify-center w-full mb-8">
              {" "}
              {/* Increased margin bottom */}
              <OrgHeader delay={0.5} className="w-1/2" />
            </div>
            <div className="space-y-4 flex flex-col justify-center">
              {" "}
              {/* Added justify-center */}
              {departmentsWithTasks.map((dept, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{
                    opacity: activeIndex >= i ? 1 : 0,
                    x: activeIndex >= i ? 0 : -50,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: i * 0.5,
                  }}
                >
                  <DepartmentCard {...dept} />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Center Column */}
          <motion.div
            initial={{ opacity: 1, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            className="flex items-center justify-center w-[400px]"
          >
            <ProcessingCore />
            <div className="absolute">
              {Array.from({ length: 15 }).map((_, i) => (
                <Particle key={i} index={i} totalParticles={15} />
              ))}
            </div>
          </motion.div>

          {/* Right Column */}
          <div className="w-[400px] flex flex-col">
            <div className="flex justify-center w-full mb-8">
              {" "}
              {/* Increased margin bottom */}
              <RaggityHeader delay={0} className="w-1/2" />
            </div>
            <div className="space-y-4 flex flex-col justify-center">
              {" "}
              {/* Added justify-center */}
              {departmentsWithTasks.map((dept, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 1, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                >
                  <TaskCard task={dept.task} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgFlow;
