// src/components/DataFlow/ProcessingCore.js
import React from "react";
import { motion } from "framer-motion";
import { Brain, Database, Code } from "lucide-react";

const ProcessingCore = () => (
  <div className="relative w-48 h-48">
    <motion.div
      className="w-full h-full rounded-full bg-gradient-to-r from-blue-600 to-purple-600 
                   flex items-center justify-center shadow-lg relative overflow-hidden"
    >
      <span className="text-white text-3xl font-bold">raggity.ai</span>
    </motion.div>

    {/* Orbiting Icons */}
    {[Brain, Database, Code].map((Icon, index) => (
      <motion.div
        key={index}
        className="absolute top-1/2 left-1/2"
        animate={{
          rotate: 360,
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "linear",
          delay: index * -3,
        }}
        style={{
          width: "180px",
          height: "180px",
          marginLeft: "-90px",
          marginTop: "-90px",
        }}
      >
        <motion.div
          className="absolute p-2 rounded-lg bg-white/10 backdrop-blur-sm"
          animate={{ rotate: -360 }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          <Icon className="w-6 h-6 text-blue-400" />
        </motion.div>
      </motion.div>
    ))}
  </div>
);

export default ProcessingCore;
