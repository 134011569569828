import React from "react";
import {
  Typography,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SpeedOutlined from "@mui/icons-material/SpeedOutlined";
import DynamicFeedOutlined from "@mui/icons-material/DynamicFeedOutlined";
import TaskAltOutlined from "@mui/icons-material/TaskAltOutlined";

function SolutionSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const solutionPoints = [
    {
      main: "Accelerate Sales Performance:",
      supporting:
        "Automate follow-ups and deliver actionable insights to close deals faster.",
      icon: SpeedOutlined,
    },
    {
      main: "Capture Real-Time Feedback:",
      supporting:
        "Turn customer and market feedback into smarter product and strategy decisions.",
      icon: DynamicFeedOutlined,
    },
    {
      main: "Maximize SE Productivity:",
      supporting:
        "Free SEs from busywork so they can focus on high-impact, growth-driving tasks.",
      icon: TaskAltOutlined,
    },
  ];

  return (
    <Box
      sx={{
        p: { xs: 3, sm: 5 },
        borderRadius: 2,
        background:
          "linear-gradient(145deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
        position: "relative",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Decorative Icon */}
      <Box
        sx={{
          position: "absolute",
          top: -20,
          right: -20,
          fontSize: 100,
          opacity: 0.1,
          transform: "rotate(15deg)",
          color: theme.palette.primary.main,
        }}
      >
        <SpeedOutlined fontSize="inherit" />
      </Box>

      {/* Section Heading */}
      <Typography
        variant={isMobile ? "h4" : "h2"}
        gutterBottom
        align="center"
        sx={{
          color: theme.palette.text.primary,
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
          mb: { xs: 3, sm: 6 },
        }}
      >
        Meet raggity.ai: The SE Game-Changer
      </Typography>

      {/* Content Section */}
      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, sm: 5 },
          maxWidth: "800px",
          mx: "auto",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
            mb: 3,
            lineHeight: 1.4,
          }}
        >
          <Typography
            component="span"
            variant="inherit"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            raggity.ai
          </Typography>
          {
            " empowers Sales Engineers to drive growth, streamline processes, and amplify their impact across the organization."
          }
        </Typography>

        {/* Solution Points */}
        <List sx={{ mt: 2 }}>
          {solutionPoints.map((point, index) => (
            <ListItem key={index} sx={{ py: 1, px: 0 }}>
              <ListItemIcon>
                <point.icon
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 32,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant={isMobile ? "body1" : "h6"}
                      sx={{
                        fontWeight: 600,
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      {point.main}
                    </Typography>{" "}
                    <Typography
                      component="span"
                      variant={isMobile ? "body2" : "body1"}
                      sx={{
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {point.supporting}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default SolutionSection;

// import React from "react";
// import {
//   Typography,
//   Box,
//   Paper,
//   useTheme,
//   useMediaQuery,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
// } from "@mui/material";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import SpeedOutlined from '@mui/icons-material/SpeedOutlined';

// function SolutionSection() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const solutionPoints = [
//     {
//       main: "Accelerate Sales Performance:",
//       supporting:
//         "Automate follow-ups and deliver actionable insights to close deals faster.",
//     },
//     {
//       main: "Capture Real-Time Feedback:",
//       supporting:
//         "Turn customer and market feedback into smarter product and strategy decisions.",
//     },
//     {
//       main: "Maximize SE Productivity:",
//       supporting:
//         "Free SEs from busywork so they can focus on high-impact, growth-driving tasks.",
//     },
//     // {
//     //   main: "Enhancing Customer Understanding:",
//     //   supporting:
//     //     "Leveraging data-driven insights to deepen customer needs analysis, aligning solutions with real-world requirements.",
//     // },
//     // {
//     //   main: "Strengthening Product Knowledge:",
//     //   supporting:
//     //     "Enabling Sales Engineers with continuous learning and quick access to product updates, ensuring they’re always prepared to deliver value.",
//     // },
//   ];

//   return (
//     <Box
//       sx={{
//         p: { xs: 3, sm: 5 },
//         borderRadius: 2,
//         background:
//           "linear-gradient(145deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
//         position: "relative",
//         overflow: "hidden",
//       }}
//     >
//       <Box
//         sx={{
//           position: "absolute",
//           top: -20,
//           right: -20,
//           fontSize: 100,
//           opacity: 0.1,
//           transform: "rotate(15deg)",
//           color: "primary.main",
//         }}
//       >
//         <AutoAwesomeIcon fontSize="inherit" />
//       </Box>
//       <Typography
//         variant={isMobile ? "h4" : "h2"}
//         gutterBottom
//         align="center"
//         sx={{
//           color: theme.palette.text.primary,
//           textShadow: "0 2px 4px rgba(0,0,0,0.5)",
//           mb: { xs: 3, sm: 6 },
//           // fontWeight: 600,
//         }}
//       >
//         raggity.ai: Elevating Sales Engineering Excellence
//       </Typography>
//       <Paper
//         elevation={3}
//         sx={{
//           p: { xs: 3, sm: 5 },
//           maxWidth: "800px",
//           mx: "auto",
//           backgroundColor: "rgba(255, 255, 255, 0.05)",
//           borderRadius: 2,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           textAlign: "center",
//         }}
//       >
//         <Typography
//           variant={isMobile ? "h5" : "h4"}
//           sx={{
//             // fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
//             color: "rgba(255, 255, 255, 0.7)",
//             mb: 3,
//             lineHeight: 1.4,
//           }}
//         >
//           {/* At{" "} */}
//           <Typography
//             component="span"
//             variant="inherit"
//             sx={{
//               color: theme.palette.primary.main,
//               fontWeight: 600,
//             }}
//           >
//             raggity.ai
//           </Typography>
//           , empowers Sales Engineers to drive growth, streamline processes, and
//           amplify their impact across the organization.
//         </Typography>
//         <List sx={{ mt: 2 }}>
//           {solutionPoints.map((point, index) => (
//             <ListItem key={index} sx={{ py: 1, px: 0 }}>
//               <ListItemIcon>
//                 <CheckCircleOutlineIcon
//                   sx={{ color: theme.palette.primary.main, fontSize: 24 }}
//                 />
//               </ListItemIcon>
//               <ListItemText
//                 primary={
//                   <React.Fragment>
//                     <Typography
//                       component="span"
//                       variant={isMobile ? "body1" : "h6"}
//                       sx={{
//                         fontWeight: 600,
//                         color: "rgba(255, 255, 255, 0.9)",
//                       }}
//                     >
//                       {point.main}
//                     </Typography>{" "}
//                     <Typography
//                       component="span"
//                       variant={isMobile ? "body2" : "body1"}
//                       sx={{
//                         color: "rgba(255, 255, 255, 0.7)",
//                       }}
//                     >
//                       {point.supporting}
//                     </Typography>
//                   </React.Fragment>
//                 }
//               />
//             </ListItem>
//           ))}
//         </List>
//       </Paper>
//     </Box>
//   );
// }

// export default SolutionSection;
