import React, { createContext, useContext } from "react";

const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
  const features = [
    {
      title: "Automated Competitive Intelligence",
      description: "Stay ahead with real-time insights to win more deals.”",
    },
    {
      title: "Streamlined Workflows",
      description: "Automate tasks to eliminate delays and keep sales flowing.",
    },
    {
      title: "Actionable SE Insights",
      description: "Unlock key pain points to drive better decisions, faster.",
    },
    {
      title: "Real-Time Customer Feedback",
      description:
        "Capture live sentiment to adapt to customer needs instantly.",
    },
    {
      title: "Predictive Analytics & Insights",
      description: "Anticipate trends to stay ahead with smarter decisions.",
    },
  ];

  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures = () => {
  return useContext(FeaturesContext);
};
