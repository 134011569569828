// src/components/Header.js
import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import logoImage from "../images/logo-image.png"; // Import the text logo image
import { useTheme } from "@mui/material/styles";

function Header() {
  const theme = useTheme();
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.secondary.main }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center">
          <img
            src={logoImage}
            alt="Raggity Logo"
            style={{ height: "40px", marginRight: "8px" }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
