import React from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

const EnhancedGradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, 
    ${theme.palette.primary.main}, 
    ${theme.palette.secondary.main}, 
    ${theme.palette.primary.light})`,
  backgroundSize: "200% 200%",
  animation: "gradient 5s ease infinite",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textShadow:
    "0 2px 10px rgba(123, 66, 246, 0.5), 0 2px 20px rgba(0, 180, 255, 0.3)",
  fontWeight: 700,
  letterSpacing: "0.05em",
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: theme.palette.secondary.main,
  },
}));

function HeroSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper
      elevation={3}
      sx={{
        width: "100%",
        textAlign: "center",
        py: { xs: 4, sm: 6, md: 8 },
        px: { xs: 2, sm: 4 },
        background:
          "linear-gradient(180deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
      }}
    >
      <EnhancedGradientTypography
        variant={isMobile ? "h3" : "h2"}
        align="center"
        sx={{
          mb: { xs: 3, sm: 6 },
          fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
        }}
      >
        raggity.ai
      </EnhancedGradientTypography>
      <Typography variant={isMobile ? "h5" : "h4"} gutterBottom color="white">
        Strengthening the Pulse of Your Organization
      </Typography>
      <Typography
        variant={isMobile ? "h6" : "h5"}
        gutterBottom
        color="textSecondary"
        sx={{ mb: 4, maxWidth: "600px", mx: "auto" }}
      >
        The AI Assistant for Sales Engineers
      </Typography>
      <AnimatedButton
        variant="contained"
        color="primary"
        href="#signup"
        size={isMobile ? "medium" : "large"}
        sx={{ py: 1.5, px: 4, borderRadius: 50 }}
      >
        Get Early Access
      </AnimatedButton>
    </Paper>
  );
}

export default HeroSection;
