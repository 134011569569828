// src/components/DataFlow/Particle.js
import React, { useEffect, useState } from "react";
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
} from "framer-motion";

const Particle = ({ index, totalParticles }) => {
  const angle = (index / totalParticles) * Math.PI * 2;
  const radius = 120; // Distance from center

  return (
    <motion.div
      className="absolute w-2 h-2 rounded-full bg-blue-400 shadow-md"
      style={{
        left: "50%",
        top: "50%",
      }}
      animate={{
        x: [
          radius * Math.cos(angle),
          radius * Math.cos(angle + Math.PI * 0.5),
          radius * Math.cos(angle + Math.PI),
          radius * Math.cos(angle + Math.PI * 1.5),
          radius * Math.cos(angle + Math.PI * 2),
        ],
        y: [
          radius * Math.sin(angle),
          radius * Math.sin(angle + Math.PI * 0.5),
          radius * Math.sin(angle + Math.PI),
          radius * Math.sin(angle + Math.PI * 1.5),
          radius * Math.sin(angle + Math.PI * 2),
        ],
      }}
      transition={{
        duration: 8,
        ease: "linear",
        repeat: Infinity,
        times: [0, 0.25, 0.5, 0.75, 1],
      }}
    />
  );
};

export default Particle;
