// src/components/DataFlow/DataFlow.js
import React, { useMemo } from "react";
import { useScene } from "./SceneProvider";
import Particle from "./Particle";
import ConversationSnippet from "./ConversationSnippet";
import ProcessingCore from "./ProcessingCore";
import InsightCard from "./InsightCard";
import { motion } from "framer-motion";
import {
  MessagesSquare,
  GitPullRequest,
  Zap,
  FileText,
  Brain,
  Database,
  LineChart,
  Code,
  Users,
  Video,
  CheckSquare,
  Bot,
} from "lucide-react";

const CallHeader = ({ delay }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.6,
      delay,
      type: "spring",
      stiffness: 80,
    }}
    className="mb-6"
    style={{ width: "350px" }}
  >
    <div className="flex items-center gap-3 bg-blue-500/30 rounded-lg p-3 backdrop-blur-sm">
      <div className="p-2 rounded-full bg-blue-500/50">
        <Video className="w-6 h-6 text-blue-200" />
      </div>
      <div className="flex flex-col">
        {/* <span className="text-blue-200 text-sm font-medium"> */}
        <span className="text-white text-sm font-medium">
          Customer Call Insights
        </span>
        <span className="text-blue-300/60 text-sm">
          Technical Discovery Session
        </span>
      </div>
    </div>
  </motion.div>
);

const RaggityHeader = ({ delay }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.6,
      delay,
      type: "spring",
      stiffness: 80,
    }}
    className="mb-6"
    style={{ width: "350px" }}
  >
    <div className="flex items-center gap-3 bg-blue-500/30 rounded-lg p-3 backdrop-blur-sm">
      <div className="p-2 rounded-full bg-blue-500/50">
        <Bot className="w-6 h-6 text-blue-200" />
      </div>
      <div className="flex flex-col">
        {/* <span className="text-blue-300 text-sm font-medium"> */}
        <span className="text-white text-sm font-medium">
          raggity Automated Tasks
        </span>
        <span className="text-blue-300/60 text-sm">
          Extracted Tasks From Call
        </span>
      </div>
    </div>
  </motion.div>
);

const DataFlow = () => {
  const { sceneIndex } = useScene();

  const conversationSnippets = [
    {
      text: "Email Follow-up: Integration document to trial the product",
      icon: FileText,
      iconColor: "bg-blue-500",
      insight: "Generate custom technical integration guide",
    },
    {
      text: "The customer is struggling with the current documentation structure. We need a more intuitive approach.",
      icon: LineChart,
      iconColor: "bg-yellow-500",
      insight: "Documentation is preventing customers from integrating: +1",
    },
    {
      text: "Customer needs AWS K8 integration",
      icon: GitPullRequest,
      iconColor: "bg-purple-500",
      insight: "K8s AWS integration priority request",
    },
    {
      text: "The competitor's API doesn't support real-time updates.",
      icon: Zap,
      iconColor: "bg-sky-500",
      insight: "Update Battlecard to highlight our competitive advantage",
    },
  ];

  return (
    <div className="relative w-[1920px] h-[580px] bg-[#0F172A] overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        {/* Layout Container */}
        <div className="w-full max-w-[1400px] flex justify-between items-center px-16">
          {/* Left Column */}
          <div className="w-[400px] flex flex-col space-y-6">
            {sceneIndex >= 1 && (
              <>
                <div className="flex justify-center w-full">
                  <CallHeader delay={0} className="w-1/2" />
                </div>
                {conversationSnippets.map((snippet, i) => (
                  <ConversationSnippet
                    key={i}
                    text={snippet.text}
                    delay={i * 0.3}
                    index={i}
                    icon={snippet.icon}
                    iconColor={snippet.iconColor}
                  />
                ))}
              </>
            )}
          </div>

          {/* Center Column */}
          {sceneIndex >= 2 && (
            <motion.div
              className="flex items-center justify-center w-[400px]"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 1.2,
                ease: "easeOut",
              }}
            >
              <ProcessingCore />
              <motion.div
                className="absolute"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.8, // Particles start appearing after the main component
                  duration: 0.8,
                }}
              >
                {Array.from({ length: 15 }).map((_, i) => (
                  <Particle key={i} index={i} totalParticles={15} />
                ))}
              </motion.div>
            </motion.div>
          )}

          {/* Right Column */}
          {/* <div className="w-[400px] flex flex-col space-y-6"> */}
          <div className="w-[400px] flex flex-col">
            {sceneIndex >= 3 && (
              <>
                {/* New Header for Right Column */}
                {/* <div className="flex justify-center w-full "> */}
                <div className="flex justify-center w-full mb-8">
                  <RaggityHeader delay={0} className="w-1/2" />
                </div>
                <div className="space-y-4 flex flex-col justify-center">
                  {conversationSnippets.map((snippet, i) => (
                    <InsightCard
                      key={i}
                      insight={{
                        type: "action",
                        icon: <snippet.icon className="w-6 h-6 text-white" />,
                        text: snippet.insight,
                        color: snippet.iconColor,
                      }}
                      index={i}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataFlow;
