// src/App.js
import React, { useState, useEffect } from "react";
import { ThemeProvider, CssBaseline, Container, Box } from "@mui/material";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ProblemSection from "./components/ProblemsSection";
import SolutionSection from "./components/SolutionSection";
import FeaturesSection from "./components/FeaturesSection";
import SignupSection from "./components/SignupSection";
import Footer from "./components/Footer";
import SETimeAllocationChart from "./components/SETimeAllocationChart";
import ComplexityAnimation from "./components/animations/Complexity";
import PyramidEffect from "./components/animations/PyramidFlow";
import SceneProvider from "./components/animations/DataFlow/SceneProvider";
import DataFlow from "./components/animations/DataFlow/DataFlow";
import OrgFlow from "./components/animations/OrgFlow";
import { FeaturesProvider } from "./contexts/FeaturesProvider"; // Import the provider
import theme from "./Theme";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200); // Check if width is below 1200px
    };

    // Initial check
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <FeaturesProvider>
        <Header />
        <CssBaseline />
        <Container maxWidth="xl">
          <Box
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
              py: 4,
            }}
          >
            <HeroSection />
            <ProblemSection />
            <SolutionSection />
            {!isMobile && (
              <>
                <SceneProvider>
                  <DataFlow />
                </SceneProvider>
              </>
            )}
            <FeaturesSection />
            {!isMobile && <OrgFlow />}
            <SignupSection />
          </Box>
        </Container>
        <Footer />
      </FeaturesProvider>
    </ThemeProvider>
  );
}

export default App;
