import React from "react";
import { Typography, Box, Container, Link } from "@mui/material";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "primary.secondary", // Ensuring this matches the primary color across the app
        color: "#fff", // Consistent white color for the footer text
        py: 2,
        mt: "auto", // This will push the footer to the bottom if content is short
        textAlign: "center", // Center-align the content
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="body1"
          align="center"
          sx={{
            color: "white",
            textShadow: "0 2px 4px rgba(0,0,0,0.5)", // Consistent text shadow for footer text
            mb: 1,
          }}
        >
          &copy; {new Date().getFullYear()} Raggity. All rights reserved.
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: "white",
            textShadow: "0 1px 2px rgba(0,0,0,0.3)",
            mb: 1, // Reduced margin between text
          }}
        >
          Built by SEs for SEs.
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            gap: 2, // Space between the links (instead of using mx)
          }}
        >
          <Link
            href="#"
            color="inherit"
            sx={{
              mx: 2,
              textDecoration: "none",
              fontWeight: 500,
              "&:hover": {
                color: "secondary.main", // Add color change on hover
                textDecoration: "underline",
                transform: "scale(1.05)", // Slight scale effect on hover
              },
            }}
          >
            Privacy Policy
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              mx: 2,
              textDecoration: "none",
              fontWeight: 500,
              "&:hover": {
                color: "secondary.main",
                textDecoration: "underline",
                transform: "scale(1.05)",
              },
            }}
          >
            Terms of Service
          </Link>
          <Link
            href="mailto:Joe@raggity.ai"
            color="inherit"
            sx={{
              mx: 2,
              textDecoration: "none",
              fontWeight: 500,
              "&:hover": {
                color: "secondary.main",
                textDecoration: "underline",
                transform: "scale(1.05)",
              },
            }}
          >
            Contact Us
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
