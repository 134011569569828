import React from "react";
import { Typography, Box, Paper, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SpeedIcon from "@mui/icons-material/Speed";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import GroupIcon from "@mui/icons-material/Group";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import TuneIcon from "@mui/icons-material/Tune";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LockClockIcon from "@mui/icons-material/LockClock";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import ThumbDownOutlined from "@mui/icons-material/ThumbDownOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import LoopOutlined from "@mui/icons-material/LoopOutlined";
import TrendingDownOutlined from "@mui/icons-material/TrendingDownOutlined";

const iconMap = {
  "Missed Follow-Ups Break Deals": LinkOffIcon,
  "Outdated POVs Frustrate Customers": ThumbDownOutlined,
  "Lack of Competitive Insights": VisibilityOffOutlined,
  "Repetitive Tasks Drain SE Energy": LoopOutlined,
  "Missed Market Trends Hurt Strategy": TrendingDownOutlined,
};

const problems = [
  {
    title: "Missed Follow-Ups Break Deals",
    description:
      "Slow follow-ups kill momentum, leading to lost deals and eroded trust.",
  },
  {
    title: "Outdated POVs Frustrate Customers",
    description: "Stale POVs derail projects and misalign expectations.",
  },
  {
    title: "Lack of Competitive Insights",
    description:
      "Without real-time data, teams miss shifts that drive innovation.",
  },
  {
    title: "Repetitive Tasks Drain SE Energy",
    description:
      "Busywork like updates and research blocks SEs from strategic work.",
  },
  {
    title: "Missed Market Trends Hurt Strategy",
    description:
      "Ignoring competitor moves leaves SEs unprepared and reactive.",
  },
];

const ProblemCard = ({ icon, title, description }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%", // Ensures all tiles have the same width
        height: "100%", // Ensures all tiles have the same height
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ mb: 2, color: "primary.main" }}>{icon}</Box>
      <Typography variant="h6" component="h3" gutterBottom color="white">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Paper>
  );
};

function ProblemSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 4 },
        borderRadius: 2,
        background:
          "linear-gradient(145deg, rgba(123, 66, 246, 0.1) 0%, rgba(0, 180, 255, 0.1) 100%)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background icon */}
      <Box
        sx={{
          position: "absolute",
          top: -20,
          right: -20,
          fontSize: 100,
          opacity: 0.1,
          transform: "rotate(15deg)",
          color: "primary.main",
        }}
      >
        <SpeedIcon fontSize="inherit" />
      </Box>

      {/* Title */}
      <Typography
        variant={isMobile ? "h4" : "h2"}
        gutterBottom
        align="center"
        sx={{
          mb: { xs: 3, sm: 6 },
          color: "white",
          textShadow: "0 2px 4px rgba(0,0,0,0.5)",
        }}
      >
        Barriers to SE Success
      </Typography>

      {/* Features grid */}
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {problems.map((feature, idx) => {
          const IconComponent = iconMap[feature.title] || SpeedIcon;
          return (
            <Grid
              key={idx}
              size={{ xs: 12, sm: 6, md: 4 }}
              sx={{ display: "flex" }}
            >
              <ProblemCard
                icon={<IconComponent sx={{ fontSize: 48 }} />}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default ProblemSection;
