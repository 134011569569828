// src/components/DataFlow/InsightCard.js
import React from "react";
import { motion } from "framer-motion";

const InsightCard = ({ insight, index }) => (
  <motion.div
    initial={{ opacity: 0, x: 50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{
      duration: 0.6,
      delay: index * 0.3,
      type: "spring",
      stiffness: 80,
    }}
    className={`${insight.color} bg-opacity-20 rounded-lg p-4 flex items-center relative overflow-hidden`}
  >
    {/* Pulsing overlay */}
    <motion.div
      className="absolute inset-0 bg-white/100"
      animate={{
        opacity: [0, 0.1, 0],
        scale: [1, 1.05, 1],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
        times: [0, 0.5, 1],
      }}
    />

    {/* Content with relative positioning to stay above pulse */}
    <div
      className={`${insight.color} p-2 rounded-lg bg-opacity-80 mr-3 relative z-10`}
    >
      {insight.icon}
    </div>
    <span className="text-white text-sm font-medium relative z-10">
      {insight.text}
    </span>
  </motion.div>
);

export default InsightCard;
