// src/components/DataFlow/ConversationSnippet.js
import React from "react";
import { motion } from "framer-motion";
import { MessagesSquare, Video, FileText } from "lucide-react";

const ConversationSnippet = ({ text, delay, index, icon: Icon, iconColor }) => (
  <motion.div
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 50 }}
    transition={{
      duration: 0.6,
      delay: delay + 0.3,
      type: "spring",
      stiffness: 80,
    }}
    className="relative bg-gradient-to-r from-blue-500/20 to-blue-500/10 rounded-lg p-4
               shadow-md transform-gpu flex items-center overflow-hidden"
  >
    {/* Animated wave overlay with index-based offset */}
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent"
      animate={{
        x: ["-100%", "100%"],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
        delay: index * 1.2 + 0.3, // Offset each wave based on index
      }}
      style={{
        width: "70%",
      }}
    />

    {/* Content stays the same */}
    <div
      className={`p-2 rounded-lg ${iconColor} bg-opacity-40 mr-3 relative z-10`}
    >
      <Icon className="w-6 h-6 text-white" />
    </div>
    <p className="text-white text-sm leading-relaxed flex-1 relative z-10">
      {text}
    </p>
  </motion.div>
);

export default ConversationSnippet;
